.nav-brand {
  color: white;
  font-weight: bold;
}

.custom-nav-item {
  padding: 10px 14px;
  margin: 4px 0px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  text-decoration: none;
  color: #94a3b8;
  gap: 5px;
  align-items: center;
}

.custom-nav-item.active {
  color: var(--primary-color);
  font-weight: 900;
}

.custom-nav-item:hover,
.custom-nav-item.active:hover {
  color: var(--other-color);
  border-radius: 4px;
  transition: all 0.3s ease;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  color: black;
}

.image-select-zone {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #d0e0f9;
  margin-top: 9px;
  color: #0875dc;
  font-weight: 500;
  padding: 20px;
}
