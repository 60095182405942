* {
  font-family: "Poppins";
  margin: 0px;
  padding: 0px;
}
:root {
  --primary-color: #d62639;
  --secondary-color: #400b11;
  --accent-color: #d62639;
  --text-color: #515151;
  --background-color: #d0e0f9;
  --other-color: rgba(196, 28, 48, 0.705);
}

.container-primary-hue {
  position: relative;
  background-color: #010803; /* Your dark background color */
  /* Rest of your styles for the container */
}

.container-primary-hue::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px; /* Height of the red gradient overlay */
  background: linear-gradient(
    to bottom,
    #d6263856 0%,
    rgba(218, 38, 57, 0) 100%
  );
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2; /* Ensures content is above the gradient overlay */
  /* Rest of your styles for the content */
}
